.logo {
    margin: auto;
    width: 158px;
    height: 20px;
}
.logoHorizontallyCenter {
    position: absolute;
    left: 50%;
    /*top: 42%;*/
    /*transform: translate(-50%, -42%);*/
    top: 50%;
    transform: translate(-50%, -50%);
}

.grow {
    flex-grow: 1;
}

.homeIcon {
    width: 20px;
    padding-top: 2px;
}

.homeIconLabel {
    font-size: 7px;
    line-height: 0.4;
    letter-spacing: 0em;
}

a.homeButton {
    padding: 0;
}

a.homeButton span {
    display: block;
}

.backButton {
    position: relative;
    left: -6px;
    color: white !important;
}
.notificationButton {
    color: white !important;
}